<div class="site-wrapper" id="livestream">
  <div class="container-fluid heading-adjust">
    <div class="row">
      <main class="col-sm-12">
        <header class="header">
          <div class="heading">
            <h1 class="heading1">Livestream <span class="livestream-pulse">•</span></h1>
            <h2 class="event-title uppercase" [innerHTML]="streamName"></h2>
          </div>
          <div class="logos">
            <!-- <div class="child">
              <img loading="lazy" class="img-responsive" src="/assets/images/livestream/event-logo-allstar.jpg" alt="{{ streamName }} - Logo" >
            </div> -->
          </div>
        </header>
        <!-- <livestream-embed></livestream-embed> -->
      </main>
    </div>
  </div>
</div>
