 <div class="well text-center">
  <h1 class="hero-heading" style="color: black;">Don't say we didn't warn you.</h1>
  <button
    id="warning-btn" class="btn btn-primary btn-block" (click)="openSearchTipsModal(searchTipsModal, $event)"
  >
    <i class="fa fa-warning"></i> <span>Read these notices. Do it.</span> <i class="fa fa-warning"></i>
  </button>
</div>

<ng-template #searchTipsModal>
  <div class="modal-container modal-container-lg">
    <div class="modal-header">
      <button class="modal-close" (click)="searchTipsModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body">
      <div class="tip-list">
        <p>
          <i class="fa fa-warning"></i>&nbsp; <strong>NOTICE!!</strong> THE DODGE HORSEPOWER LOCATOR HAS THE ABILITY TO CHANGE YOUR LIFE. EXCESSIVE SEARCHING COULD CAUSE ISSUES FOR YOUR VISION, RELATIONSHIP STATUS AND CONTENTMENT WITH YOUR CURRENT VEHICLE. HORSEPOWER IS KNOWN TO BE AN ADDICTIVE SUBSTANCE. YOU SHOULD CONSULT YOUR GOOD OL' COMMON SENSE, A LAWYER, YOUR DOCTOR, YOUR SPIRITUAL LEADER AND YOUR MOM BEFORE ENTERING INTO AN AGREEMENT FOR MORE HORSEPOWER. THEN DO IT ANYWAY.
        </p>
        <ul>
          <li>
            Step up or step aside - Be the first to put your name down on a First Call Dodge Charger Daytona. If muscle cars aren't your thing (you know where you are, right?) maybe one of the (also badass) Durango Special Editions fits your style. They're all pre-configured, already “ordered” by the dealers, and are either the first of their kind or very limited production. We encourage you to <span class="text-strong-underline">act fast</span> by working with a Dealer to get your name on one. Seriously - call them. Because of this unique method, it will be <span class="text-strong-underline">very difficult</span> for us to know when each dealer has buyers for the units they will receive. Units showing available on the Horsepower Locator may already be reserved. <span class="text-strong-underline">Please contact the dealers listed with availability for their current status of First Call and Special Edition availability.</span>
          </li>
          <li>
            You see these dealers on the list? These are the ones with the goods. Don't waste your time if they aren't on here. We want you to be happy and that's why we are LITERALLY telling you where to shop.
          </li>
          <li>
            You can download a PDF of your search results for the first 100 dealers. Print, call and most importantly, take notes because you'll wish you had. Just saying.
          </li>
          <li>
            Want to get up close and personal with the Dodge Charger Daytona? Well, virtually at least. <a href="https://www.dodge.com/dodge-experience/index.html" target="_blank"><strong>Visit the Next-Gen Dodge Charger configurable experience and mix and match the options and colors until your heart is content.</strong></a> Or don't. Whatever you do with your spare time is up to you. But you totally should.
          </li>
          <li>
            Not into these First Calls or Special Editions? Weird, but ok. To see regular trim Durango stock orders that dealers placed that are still up for grabs, please <a href="https://www.dodge.com/vehicle-selector.sni.html?app=sni" target="_blank"><strong>SEARCH NEW INVENTORY on Dodge.com.</strong></a> You'll see the stock units being built, in transit and currently available on dealer lots. Check back for Charger Daytonas. 
          </li>
          <li>
            You can also check out the <a href="https://www.dodge.com/bmo.durango.html#/models/2024/durango" target="_blank"><strong>Durango Build & Price on Dodge.com</strong></a> to configure options and see MSRP for standard trims before contacting a dealer, who will determine final pricing.
          </li>
          <li>
            The results database is updated frequently, excluding holidays, because even we have families that we like to see on occasion. The results are also subject to change without notice as dealers can place orders any time of day or night. If you'd like to apply for the position of manually updating all of these numbers in real time, call a psychiatrist, not us.
          </li>
          <li>
            The allocation process is a forward-looking statement based on the Dodge Brand's current state of knowledge, future expectations and projections and is not a guarantee to deliver referenced units to a customer or dealer. After all, we have inhaled a lot of burning rubber over the past few years. These future expectations and projections are, by their nature, subject to inherent risks and uncertainties (the lawyers made us say it). Actual results may differ materially from those expressed as a result of a variety of factors, including: the impact of the COVID-19 pandemic; our ability to launch new products successfully and to maintain vehicle shipment volumes; a significant malfunction; disruption or security breach compromising information technology systems; disruptions arising from political, social and economic instability; risks associated with our relationships with employees, dealers and suppliers; increases in costs; disruptions of supply or shortages of raw materials, parts, components and systems used in vehicle developments in labor and industrial relations and developments in applicable labor laws; exchange rate fluctuations; interest rate changes; credit risk and other market risks; political and civil unrest; earthquakes or other disasters; damage in transit or theft; and other risks and uncertainties. Actually, the lawyers made us say all of this. At the end of the day, if we don't sell cars, we don't make money. It's that simple.
          </li>
          <li>
            <strong>TLDR Version:</strong> We'll do our best to help you find your dream Dodge vehicle. The Horsepower Locator will help but can't foresee the future.
          </li>
          <li>
            Are you still here? You actually read this entire list of notices? There might be hope for you after all. You're our kind of crazy. Now get searching.
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
