<nav id="primary-nav" class="navbar navbar-default" [ngClass]="{'is-server' : isServer}">
  <div class="navbar-header" #navHeader>
    <div class="navbar-content">
      <div class="control">
        <button aria-expanded="false" class="navbar-toggle collapsed" (click)="toggleCollapse()" (focus)="toggleCollapse()" type="button" tabindex="0">
          <span class="sr-only">Toggle navigation</span>
          <i *ngIf="collapse == 'closed'" class="fa fa-bars"></i>
          <i *ngIf="collapse == 'open'" class="fa fa-close"></i>
        </button>
      </div>
      <div class="navbar-collapse" [ngClass]="{'in-view' : collapse == 'open'}">
        <!-- Navbar Open -->
        <ul class="nav navbar-nav">
          <li class="nav-item nav-item-has-children signup-share-mobile mobile-only" dropdown>
            <!-- Mobile Account Login -->
            <a *ngIf="!isLoggedIn" href="#" class="signup-share ga-event" tabindex="0" (click)="navigationGASignUp($event);userService.clearMessages();collapseOnModal();openUserModal($event);trackLoginClicks()" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Sign Up">
              <span><i class="fa fa-user"></i> Log in</span>
            </a>
            <!-- Mobile User Dropdown -->
            <a *ngIf="isLoggedIn" href class="signup-share ga-event" role="button" aria-haspopup="true" aria-expanded="false" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Account Management" dropdownToggle aria-controls="user-menu-dropdown-mobile" (click)="false">
              <span>
                <i class="fa fa-cog"></i>&nbsp;
                <span id="nav-username-mobile">{{userService.userModel.username}}</span>&nbsp;
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </span>
            </a>
            <ng-container *ngIf="isLoggedIn">
              <ul class="dropdown-menu dropdown-menu-right" id="user-menu-dropdown-mobile" *dropdownMenu>
                <li aria-labelledby="user-menu-item-mobile" *ngIf="userService.userModel.isPublic"><a [href]="'/user/'+userService.userModel.username" class="header-nav ga-event" data-dest="My Account" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Account Management - My Account">My Garage</a></li>
                <li aria-labelledby="user-menu-item-mobile"><a routerLink="/manage-account" class="header-nav ga-event" data-dest="My Account" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Account Management - My Account">Manage Account</a></li>
                <li aria-labelledby="user-menu-item-mobile"><a href="#" class="header-nav ga-event" data-dest="Log Out" (click)="logout($event)" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Account Management - Log Out">Log out</a></li>
              </ul>
            </ng-container>
          </li>
          <li class="nav-item nav-item-has-children" dropdown #newsMenuDropdown="bs-dropdown" triggers="hover focus" tabindex="0" (click)="false" (focus)="eventsMenuDropdown.hide()" (mouseleave)="newsMenuDropdown.hide()">
            <div role="button" class="header-nav ga-event" [class.active]="isNewsActive" data-dest="News" data-ga_category="Nav" data-ga_action="Header" data-ga_label="News" id="news-menu-item" dropdownToggle [attr.aria-controls]="(newsMenuDropdown.isOpen)?'news-menu-dropdown':null" (click)="false">
              <span>News</span>
            </div>
            <ul id="news-menu-dropdown" *dropdownMenu  class="dropdown-menu">
              <li aria-labelledby="news-menu-item" *ngFor="let category of categories">
                <a [routerLink]="category.link" [routerLinkActive]="['active']" (click)="trackMenuItemClicks('news', category.slug)">{{category.name}}</a>
              </li>
              <li aria-labelledby="news-menu-item"><a routerLink="/contributors" [routerLinkActive]="['active']" (click)="trackMenuItemClicks('news', 'contributors')">Contributors</a></li>
            </ul>
          </li>
          <li class="nav-item nav-item-has-children" dropdown #eventsMenuDropdown="bs-dropdown" triggers="hover focus" tabindex="0" (click)="false" (focus)="newsMenuDropdown.hide();racingHqMenuDropdown.hide()" (mouseleave)="eventsMenuDropdown.hide()">
            <div role="button" class="header-nav ga-event" [class.active]="isEventsActive" data-dest="Events" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Events" id="events-menu-item" dropdownToggle [attr.aria-controls]="(eventsMenuDropdown.isOpen)?'events-menu-dropdown':null" (click)="false">
              <span>Events</span>
            </div>
            <ul id="events-menu-dropdown" *dropdownMenu  class="dropdown-menu">
              <li><a routerLink="/events" (click)="trackMenuItemClicks('events', 'map-search')">Map &amp; Search</a></li>
              <li><a routerLink="/events" fragment="upcoming-events" (click)="trackMenuItemClicks('events', 'upcoming-events')">Upcoming Events</a></li>
              <li><a routerLink="/events" fragment="dodge-official" (click)="trackMenuItemClicks('events', 'dodge-onsite')">Dodge Onsite</a></li>
              <li><a routerLink="/events" fragment="past-events" (click)="trackMenuItemClicks('events', 'past-events')">Past Events</a></li>
              <li><a routerLink="/never-lift" (click)="trackMenuItemClicks('events', 'never-lift')">Never Lift</a></li>
              <li><a routerLink="/roadkill-nights" (click)="trackMenuItemClicks('events', 'roadkill-nights')">Roadkill Nights</a></li>
            </ul>
          </li>
          <li class="nav-item nav-item-has-children" dropdown #racingHqMenuDropdown="bs-dropdown" triggers="hover focus" tabindex="0"  (click)="false" (focus)="eventsMenuDropdown.hide();mediaMenuDropdown.hide()" (mouseleave)="racingHqMenuDropdown.hide()">
            <div role="button" class="header-nav ga-event" [class.active]="isRacingHQActive" data-dest="Racing HQ" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Racing HQ" id="racing-hq-menu-item" dropdownToggle  [attr.aria-controls]="(racingHqMenuDropdown.isOpen)?'racing-hq-menu-dropdown':null" (click)="false">
              <span>Racing HQ</span>
            </div>
            <ul id="racing-hq-menu-dropdown" *dropdownMenu  class="dropdown-menu">
              <li><a routerLink="/racing-hq" (click)="trackMenuItemClicks('racing-hq', 'main-hq')">Main HQ</a></li>
              <li><a routerLink="/1320-club" (click)="trackMenuItemClicks('racing-hq', '1320-club')">1320 Club</a></li>
              <li><a routerLink="/racing-hq" fragment="current-season-info" (click)="trackMenuItemClicks('racing-hq', 'current-season-info')">Current Season Info</a></li>
              <li><a routerLink="/videos" (click)="trackMenuItemClicks('racing-hq', 'videos')">Videos</a></li>
              <li><a routerLink="/events/category/racing" (click)="trackMenuItemClicks('racing-hq', 'racing-events')">Racing Events</a></li>
              <li><a routerLink="/news/category/racing" (click)="trackMenuItemClicks('racing-hq', 'racing-news')">Racing News</a></li>
              <li><a routerLink="/track-locator" (click)="trackMenuItemClicks('racing-hq', 'track-locator')">Track Locator</a></li>
              <li><a routerLink="/racing-hq/racing-faq" (click)="trackMenuItemClicks('racing-hq', 'drag-racing-faqs')">Drag Racing FAQs</a></li>
              <li><a routerLink="/racing-hq" fragment="cars-and-classes" (click)="trackMenuItemClicks('racing-hq', 'cars-and-classes')">Cars and Classes</a></li>
            </ul>
          </li>
          <li class="nav-item nav-item-has-children" dropdown #mediaMenuDropdown="bs-dropdown" triggers="hover focus" tabindex="0" (click)="false" (focus)="racingHqMenuDropdown.hide();merchMenuDropdown.hide();" (mouseleave)="mediaMenuDropdown.hide()">
            <div role="button" class="header-nav ga-event" [class.active]="isMediaActive" data-dest="Media" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Media" id="media-menu-item" dropdownToggle  [attr.aria-controls]="(mediaMenuDropdown.isOpen)?'media-menu-dropdown':null" (click)="false">
              <span>Media</span>
            </div>
            <ul id="media-menu-dropdown" *dropdownMenu  class="dropdown-menu">
              <li><a routerLink="/videos" [routerLinkActive]="['active']" class="header-nav ga-event" data-dest="Videos" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Videos" (click)="trackMenuItemClicks('media', 'videos')"><span>Videos</span></a></li>
              <li><a routerLink="/gallery" [routerLinkActive]="['active']" class="header-nav ga-event" data-dest="Gallery" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Gallery" (click)="trackMenuItemClicks('media', 'gallery')"><span>Gallery</span></a></li>
              <li><a routerLink="/downloads" [routerLinkActive]="['active']" class="header-nav ga-event" data-dest="Downloads" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Downloads" (click)="trackMenuItemClicks('media', 'downloads')"><span>Downloads</span></a></li>
            </ul>
          </li>
          <li class="nav-item nav-item-has-children" dropdown #merchMenuDropdown="bs-dropdown" triggers="hover focus" tabindex="0" (focus)="mediaMenuDropdown.hide()" (mouseleave)="merchMenuDropdown.hide()">
            <div role="button" class="header-nav ga-event" [class.active]="isMerchActive" data-dest="Merch" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Merch" id="merch-menu-item" dropdownToggle [attr.aria-controls]="(merchMenuDropdown.isOpen)?'merch-menu-dropdown':null" (click)="false">
              <span>Merch</span>
            </div>
            <ul id="merch-menu-dropdown" *dropdownMenu  class="dropdown-menu">
              <li><a class="header-nav ga-event" data-dest="Dodge Official" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Dodge Official" href="https://www.life.dodge.com/" target="_blank" (click)="trackMenuItemClicks('merch', 'dodge-official')">Dodge Official</a></li>
              <li><a href="http://bit.ly/Dodge_Garage" target="_blank" (click)="trackMenuItemClicks('merch', 'dodge-amazon-store')">Dodge Amazon Store</a></li>
            </ul>
          </li>
          <li class="nav-item nav-item-visible mobile">
            <a href="https://www.dodge.com/" target="_blank" class="header-nav ga-event" data-dest="Dodge.com" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Dodge.com" (click)="trackTopMenuItemClicks('t1-dodge')">
              Dodge.com
            </a>
          </li>
          <li class="nav-item nav-item-visible mobile">
            <a href="https://dcperformance.com/" target="_blank" class="header-nav ga-event" data-dest="DCPerformance.com" data-ga_category="Nav" data-ga_action="Header" data-ga_label="DCPerformance.com" (click)="trackTopMenuItemClicks('dc-performance')">
              DCPerformance.com
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="logo-container">
      <a class="navbar-brand header-nav ga-event" data-dest="/" routerLink="/" data-ga_category="Primary Navigation" data-ga_action="Click" data-ga_label="Brand Logo" (focus)="merchMenuDropdown.hide();" (click)="trackLogoClicks()">
        <img loading="lazy" class="img-responsive" src="/assets/images/logos/dodge-garage-logo.png" alt="Dodge Garage - Logo" width="177" height="42">
      </a>
    </div>
    <div class="nav-item-visible">
      <a href="https://www.dodge.com/" target="_blank" class="header-nav ga-event" data-dest="Dodge.com" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Dodge.com" (click)="trackTopMenuItemClicks('t1-dodge')">
        Dodge.com
      </a>
    </div>
    <div class="nav-item-visible">
      <a href="https://dcperformance.com/" target="_blank" class="header-nav ga-event" data-dest="DCPerformance.com" data-ga_category="Nav" data-ga_action="Header" data-ga_label="DCPerformance.com" (click)="trackTopMenuItemClicks('dc-performance')">
        DCPerformance.com
      </a>
    </div>
    <div class="user-controls">
      <ul>
        <li class="nav-item nav-item-has-children signup-share-mobile" dropdown>
          <!-- Mobile Account Login -->
          <a *ngIf="!isLoggedIn" href="#" class="signup-share ga-event" (click)="navigationGASignUp($event);userService.clearMessages();collapseOnModal();openUserModal($event);trackLoginClicks()" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Sign Up">
            <span><i class="fa fa-user"></i> Log in</span>
          </a>
          <!-- Mobile User Dropdown -->
          <a *ngIf="isLoggedIn" href class="signup-share ga-event" role="button" aria-haspopup="true" aria-expanded="false" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Account Management" dropdownToggle aria-controls="user-menu-dropdown-mobile" (click)="false">
            <span>
              <i class="fa fa-cog"></i>&nbsp;
              <span id="nav-username-mobile">{{userService.userModel.username}}</span>&nbsp;
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </span>
          </a>
          <ng-container *ngIf="isLoggedIn">
            <ul class="dropdown-menu dropdown-menu-right" id="user-menu-dropdown-mobile" *dropdownMenu>
              <li aria-labelledby="user-menu-item-mobile" *ngIf="userService.userModel.isPublic"><a [href]="'/user/'+userService.userModel.username" class="header-nav ga-event" data-dest="My Account" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Account Management - My Account">My Garage</a></li>
              <li aria-labelledby="user-menu-item-mobile"><a routerLink="/manage-account" class="header-nav ga-event" data-dest="My Account" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Account Management - My Account">Manage Account</a></li>
              <li aria-labelledby="user-menu-item-mobile"><a href="#" class="header-nav ga-event" data-dest="Log Out" (click)="logout($event)" data-ga_category="Nav" data-ga_action="Header" data-ga_label="Account Management - Log Out">Log out</a></li>
            </ul>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>

  <div class="banner-container" #navBanner>
    <!-- <dg-dodge-neverlift-banner *ngIf="!routeRestricted"></dg-dodge-neverlift-banner> -->
  </div>
</nav>

<!-- User Modal -->
<ng-template #userModal>
  <div class="modal-container">
    <div class="modal-header">
      <button class="modal-close" (click)="closeUserModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <div class="signup-form form-1320">
        <dg-user-onboarding class="step-form" (userLoggedIn)="closeUserModal()"></dg-user-onboarding>
      </div>
    </div>
  </div>
</ng-template>

<!-- Missing User Location Modal -->
<div class="modal fade" bsModal #missingUserLocationModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="location-modal-title" (onHide)="closeLocationModal()">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" style="padding-bottom: 0;">
        <p id="location-modal-title" class="modal-title heading1 pull-left">Help us help you</p>
        <button class="modal-close" (click)="missingUserLocationModal.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
      </div>
      <div class="modal-body">
        <p>Add your location so we can help connect you with local events and ticket offerings!</p>
        <div>
          <a *ngIf="isLoggedIn" class="btn btn-primary" routerLink="/manage-account" (click)="missingUserLocationModal.hide()">Take Me to My Profile</a>&nbsp;&nbsp;
          <button class="btn btn-outline-primary" (click)="missingUserLocationModal.hide()">Not Right Now</button>
        </div>
      </div>
    </div>
  </div>
</div>
